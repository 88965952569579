/* 
Material UI breakpoints

sm, small: 600px
md, medium: 960px 
*/

@font-face {
    font-family: "Blair itc";
    src: URL("../assets/fonts/blair-itc-medium.ttf") format("truetype");
    font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");

#root {
    overflow-x: hidden;
    overflow-y: hidden;

    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    max-height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: "Blair itc", sans-serif;
    overflow-x: hidden;
}

html {
    font-family: "Blair itc", sans-serif;
    font-size: 62.5%;
}

/* Button styles */

.btn-blue {
    background: #a4b1d5;
    cursor: pointer;
    box-shadow: none !important;
    border-radius: 0;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
}

.btn-blue:hover,
.btn-blue:active,
.btn-blue:focus {
    background: #a4b1d5;
}

/* Text styles */

.textBold {
    font-weight: bold;
}

.relativeContainer {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

/* ReactCSSTransitionGroup styles */

.revealElement-enter {
    opacity: 0;
}

.revealElement-enter-active {
    opacity: 1;
    transition: all 400ms linear;
}

.revealElement-leave {
    opacity: 1;
}

.revealElement-leave-active {
    opacity: 0;
    transition: all 400ms linear;
}

/* End of ReactCSSTransitionGroup styles */

h1, .fs-h1 {
    font-size: 2.8rem;
    line-height: 1.2;
}

h2, .fs-h2 {
    font-size: 2.2rem;
    line-height: 1.4;
}

h3, .fs-h3 {
    font-size: 1.8rem;
    line-height: 1.4;
}

p, .fs-description {
    font-size: 1.4rem;
    line-height: 1.5;
}

.fs-large-description {
    font-size: 1.8rem;
    line-height: 2;
}

p, .fam-lora {
    font-family: "Lora", sans-serif !important;
}

.btn {
    padding: 2rem;
    border: 1px solid black;
    font-size: 1.5rem;
    text-decoration: none;

    transition: all ease-in 200ms;
}

.btn:hover {
    transform: scale(1.05);
}

.btn-black {
    background-color: black;
    color: white;
}

.btn.btn-block {
    width: 100%;
}

.pink-border {
    box-sizing: border-box;
    border: 0.6rem solid #facbd7;
}

@media (max-width: 601px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media (max-width: 961px) {
    .hide-on-mobile {
        display: none !important;
    }
}

@media (min-width: 960px) {
    .hide-on-desktop {
        display: none !important;
    }
}