.form-group {
  box-sizing: border-box;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem;
  display: inline-block;
}

.form-group .error {
  color: gray;
}

.form-group input {
  margin-bottom: 0.5rem;
}

.form-group.half-width {
  width: 50%;
}

.ContactFormHome {
  margin: 200px 100px;
}

.ContactFormContent {
  margin: 0 1.68rem;
}

.ContactFormContent form {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.ContactFormContent h1 {
  text-align: center;
  margin-bottom: 5rem;
  font-size: 2em;
}

.contact-form__modal {
  padding: 4rem;
  text-align: center;
}

.contact-form__modal > *:not(:first-child) {
  margin-top: 2em;
}

.btn {
  padding: 0 2rem;
}

.btn-blue {
  background-color: #a4b1d5;
}

button:focus {
  outline: none;
  color: #00aeef;
  background: #464d55;
}

input:not([type]):focus:not([readonly]), input[type=text]:focus:not([readonly]), input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search]:focus:not([readonly]), textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid #a4b1d5;
  box-shadow: 0 1px 0 0 #a4b1d5;
}

input:not([type]):focus:not([readonly])+label, input[type=text]:focus:not([readonly])+label, input[type=password]:focus:not([readonly])+label, input[type=email]:focus:not([readonly])+label, input[type=url]:focus:not([readonly])+label, input[type=time]:focus:not([readonly])+label, input[type=date]:focus:not([readonly])+label, input[type=datetime]:focus:not([readonly])+label, input[type=datetime-local]:focus:not([readonly])+label, input[type=tel]:focus:not([readonly])+label, input[type=number]:focus:not([readonly])+label, input[type=search]:focus:not([readonly])+label, textarea.materialize-textarea:focus:not([readonly])+label {
  color: #a4b1d5;
}

.input-field .prefix.active {
  color: #a4b1d5 !important;
}

/* Media for all devices under 1100px */

@media (max-width: 961px) {
  .ContactFormHome {
    margin: 100px 0;
  }
  .form-group.half-width {
    width: 100%;
  }
}