.Contact {
    margin: 0;
    padding: 0;
}

.HeroImg {
  margin-top: 100px;
  width: 100%;
  height: 600px;
  background: url(/frontend/src/assets/RestaurantsHero.jpg) no-repeat center center scroll;
  background-size: cover;
}

@media (max-width: 961px) {
  .HeroImg {
    margin-top: 60px;
  }
}
