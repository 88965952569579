.red-box {
    position: absolute;
    text-align: center;
    width: 350px;
    height: 383px;
    top: 17%;
    right: 10%;
    bottom: 80px;
    padding: 40px;
    background: rgb(10,90,100);
    color: #fff;
}

.red-box h1 {
    font-size: 1.4rem;
    margin-top: 50%;
}

.red-box svg {
    width: 200px;
    height: 200px;
    display: block;
    position: absolute;
    margin: 20px auto;
    top: -25%;
    right: 0;
    left: 55%;
    border: 5px solid #fff;
    border-radius: 50%;
}
.arrow-down {
    width: 35px;
    height: 35px;
    z-index: 999999;
    border: 2px solid rgb(10,90,100);
    position: absolute;
    top: 48%;
    left: -17px;
    transform: rotate(45deg);
    right: 0;
    background-color:rgb(10,90,100);
}